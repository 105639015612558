import { Invoice } from "@today/api/invoice"
import { Taker } from "@today/api/taker"
import { Tracker } from "@today/api/tracker"
import { useAuthentication } from "@today/auth"
import axios from "axios"
import { NextApiRequest } from "next"
import useSWR, { Key, SWRResponse } from "swr"
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite"
import {
  INVOICE_BASE_URL,
  KEYCLOAK_JWT,
  TAKER_BASE_URL,
  TRACKER_BASE_URL,
} from "../config"

export const SWR_AUTH_TOKEN_HEADER = "x-today-openapi-token"

function extractAuthToken(req: NextApiRequest) {
  return (req.headers[SWR_AUTH_TOKEN_HEADER] ?? "") as string
}

export const tracker = new Tracker(TRACKER_BASE_URL, KEYCLOAK_JWT)
export function taker(req: NextApiRequest) {
  return new Taker(TAKER_BASE_URL, extractAuthToken(req))
}

export function invoice(req: NextApiRequest) {
  return new Invoice(INVOICE_BASE_URL, extractAuthToken(req))
}

export function useSWRWithAuth<Data, Error = any>(
  key: Key
): SWRResponse<Data, Error> {
  const { accessToken } = useAuthentication()
  return useSWR([key, accessToken], (url: string, token: string) =>
    axios
      .get(url, { headers: { [SWR_AUTH_TOKEN_HEADER]: token } })
      .then((res) => res.data)
  )
}

export function useSWRInfiniteWithAuth<Data, Error = any>(
  getKey: (index: number, previousPageData: Data) => Key
): SWRInfiniteResponse<Data, Error> {
  const { accessToken } = useAuthentication()
  return useSWRInfinite(
    (index: number, previousPageData: Data) => [
      getKey(index, previousPageData),
      accessToken,
    ],
    (url: string, token: string) =>
      axios
        .get(url, { headers: { [SWR_AUTH_TOKEN_HEADER]: accessToken } })
        .then((res) => res.data)
  )
}
