import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import {
  BulkCreateOrderResponse,
  CleanAddressResponse,
  ClientCondition,
  CreateClientConditionRequest,
  CreateClientRequest,
  CreateShippingPlaceRequest,
  ListBillingClientsParams,
  ListBillingClientsResponse,
  ListBillingOrdersParams,
  ListClientBillingOrdersParams,
  ListClientUnbilledOrdersParams,
  ListRegionsResponse,
  ListReturningOrdersResponse,
  ListTemporaryOrdersResponse,
  ListUnbilledOrdersSummaryParams,
  ListUnbilledOrdersSummaryResponse,
  Order,
  PartialUpdateClientRequest,
  PatchOrderRequest,
  RawListRegionsResponse,
  RetryTemporaryOrdersResponse,
  TaxInvoice,
} from "."
import { DeliveryClass } from "../common"
import { PaginationResponse, withPagination } from "../utils"
import {
  Billing,
  BulkCreateOrderRequest,
  Client,
  CreateBillingRequest,
  CreateOrderRequest,
  ListBillingsResponse,
  ListClientsResponse,
  ListOrdersResponse,
  ListShippingPlacesResponse,
  RetrieveRegionResponse,
  ShippingPlace,
  TemporaryOrder,
} from "./types"

export const LIST_PAGE_SIZE = "100"

export type OrderRetrievalType =
  | "order_id"
  | "invoice_number"
  | "client_shipping_id"

export class Taker {
  client: AxiosInstance

  constructor(baseUrl: string, token?: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseUrl,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      })
    )
  }

  async listClients(
    queryParams: Record<string, string>
  ): Promise<ListClientsResponse[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/client/v1/clients?${paramsString}`)
    return data as ListClientsResponse[]
  }

  async createClient(request: CreateClientRequest): Promise<Client> {
    const { data } = await this.client.post(`/client/v1/clients`, request)
    return data as Client
  }

  async retrieveClient(clientId: string): Promise<Client> {
    const { data } = await this.client.get(`/client/v1/clients/${clientId}`)
    return data as Client
  }

  async partialUpdateClient(
    clientId: string,
    req: PartialUpdateClientRequest
  ): Promise<Client> {
    const { data } = await this.client.patch(
      `/client/v1/clients/${clientId}`,
      req
    )
    return data as Client
  }

  async listBillingClients(
    clientId: string,
    params?: ListBillingClientsParams
  ): Promise<ListBillingClientsResponse> {
    const paramsString = withPagination(params)
    const { data } = await this.client.get(
      `/client/v1/clients/${clientId}/billing-clients?${paramsString}`
    )
    return data as ListBillingClientsResponse
  }

  async bulkCreateClientCondition(
    conditions: CreateClientConditionRequest[]
  ): Promise<ClientCondition> {
    const { data } = await this.client.post(
      `/client/v1/clients/conditions/bulk-create`,
      { conditions }
    )
    return data as ClientCondition
  }

  async updateClientCondition(
    clientId: string,
    conditionId: string,
    deliveryClasses?: DeliveryClass[],
    forwardingDeliveryOrganizationName?: string
  ): Promise<ClientCondition> {
    const { data } = await this.client.patch(
      `/client/v1/clients/${clientId}/conditions/${conditionId}`,
      {
        deliveryClasses,
        forwardingDeliveryOrganizationName,
      }
    )
    return data as ClientCondition
  }

  async listShippingPlacesInternal(
    pageToken = "",
    pageSize = LIST_PAGE_SIZE
  ): Promise<PaginationResponse<"shippingPlaces", ShippingPlace>> {
    const params = new URLSearchParams({
      page_token: pageToken,
      page_size: pageSize,
    })
    const { data } = await this.client.get(
      `/client/v1/shipping-places?${params}`
    )
    return data
  }

  async listShippingPlaces(
    clientId: string
  ): Promise<ListShippingPlacesResponse> {
    const { data } = await this.client.get(
      `/client/v1/clients/${clientId}/shipping-places`
    )
    return data as ListShippingPlacesResponse
  }

  async createShippingPlace(
    request: CreateShippingPlaceRequest
  ): Promise<ShippingPlace> {
    const { data } = await this.client.post(
      `/client/v1/shipping-places`,
      request
    )
    return data as ShippingPlace
  }

  async retrieveShippingPlace(shippingPlaceId: string): Promise<ShippingPlace> {
    const { data } = await this.client.get(
      `/client/v1/shipping-places/${shippingPlaceId}`
    )
    return data as ShippingPlace
  }

  async listTemporaryOrders(params: {
    fromDate?: string
    toDate?: string
    clientIds?: string[]
    isFixed?: boolean
    pageSize?: number
    pageToken?: string
  }): Promise<ListTemporaryOrdersResponse> {
    const { fromDate, toDate, clientIds, isFixed } = params
    const queryParams = new URLSearchParams()
    if (params.pageSize) {
      queryParams.append("page_size", params.pageSize.toString())
    }
    if (params.pageToken) {
      queryParams.append("page_token", params.pageToken)
    }
    const filter = [
      fromDate ? `failed_date_from=${fromDate}` : null,
      toDate ? `failed_date_to=${toDate}` : null,
      clientIds && clientIds.length > 0
        ? `client_id=${clientIds.join(",")}`
        : null,
      isFixed !== undefined ? `fixed=${isFixed ? "true" : "false"}` : null,
    ]
      .filter((d) => !!d)
      .join(";")
    if (filter) {
      queryParams.append("filter", filter)
    }
    const { data } = await this.client.get(
      `/order/v1/temporary-orders?${queryParams.toString()}`
    )
    return data
  }

  async getTemporaryOrder(orderId: string): Promise<TemporaryOrder> {
    const { data } = await this.client.get(
      `/order/v1/temporary-orders/${orderId}`
    )
    return data
  }

  async patchTemporaryOrder(
    orderId: string,
    payload: {
      senderAddress?: string
      senderPostalCode?: string
      receiverAddress?: string
      receiverPostalCode?: string
    }
  ): Promise<TemporaryOrder> {
    const {
      senderAddress,
      senderPostalCode,
      receiverAddress,
      receiverPostalCode,
    } = payload
    const { data } = await this.client.patch(
      `/order/v1/temporary-orders/${orderId}`,
      {
        ...(senderAddress
          ? {
              fixedSender: {
                address: senderAddress,
                postalCode: senderPostalCode ?? "",
              },
            }
          : {}),
        ...(receiverAddress
          ? {
              fixedReceiver: {
                address: receiverAddress,
                postalCode: receiverPostalCode ?? "",
              },
            }
          : {}),
      }
    )
    return data
  }

  async retryTemporaryOrders(
    orderIds: string[]
  ): Promise<RetryTemporaryOrdersResponse> {
    const { data } = await this.client.post(
      `/order/v1/temporary-orders/retry`,
      { ids: orderIds }
    )
    return data
  }

  async cleanAddress(
    address: string,
    postalCode?: string
  ): Promise<CleanAddressResponse> {
    const { data } = await this.client.post(`/order/v1/clean-address`, {
      address,
      ...(postalCode ? { postalCode } : {}),
    })
    return data
  }

  async listOrdersInternal(params: {
    fromDate?: string
    toDate?: string
    pageToken?: string
  }): Promise<ListOrdersResponse> {
    const { fromDate, toDate, pageToken } = params
    const queryParams = new URLSearchParams({
      page_size: LIST_PAGE_SIZE,
    })
    if (pageToken) {
      queryParams.append("page_token", pageToken)
    }
    if (fromDate || toDate) {
      queryParams.append(
        "filter",
        [
          fromDate ? `order_date_from=${fromDate}` : null,
          toDate ? `order_date_to=${toDate}` : null,
        ]
          .filter((d) => !!d)
          .join(",")
      )
    }
    const { data } = await this.client.get(
      `/order/v1/orders?${queryParams.toString()}`
    )
    return data
  }

  async listOrders(params: {
    clientId: string
    fromDate?: string
    toDate?: string
    pageToken?: string
  }): Promise<ListOrdersResponse> {
    const { clientId, fromDate, toDate, pageToken } = params
    const queryParams = new URLSearchParams({
      page_size: LIST_PAGE_SIZE,
      order: "order_time.asc",
    })
    if (pageToken) {
      queryParams.append("page_token", pageToken)
    }
    if (fromDate || toDate) {
      queryParams.append(
        "filter",
        [
          fromDate ? `order_date_from=${fromDate}` : null,
          toDate ? `order_date_to=${toDate}` : null,
        ]
          .filter((d) => !!d)
          .join(",")
      )
    }
    const { data } = await this.client.get(
      `/order/v1/clients/${clientId}/orders?${queryParams.toString()}`
    )
    return data
  }

  async listReturningOrdersInternal(
    orderId: string
  ): Promise<ListReturningOrdersResponse> {
    const { data } = await this.client.get(
      `/order/v1/orders/${orderId}/returning-orders`
    )
    return data
  }

  async createOrder(
    clientId: string,
    request: CreateOrderRequest
  ): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders`,
      request
    )
    return data as Order
  }

  async bulkCreateOrder(
    clientId: string,
    request: BulkCreateOrderRequest
  ): Promise<BulkCreateOrderResponse> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/bulk-create`,
      request
    )
    return data as BulkCreateOrderResponse
  }

  async retrieveOrder(
    clientId: string,
    orderId: string,
    type?: OrderRetrievalType
  ): Promise<Order> {
    const queryParams = new URLSearchParams()
    if (type) {
      queryParams.append("type", type)
    }
    const response = await this.client.get(
      `/order/v1/clients/${clientId}/orders/${orderId}?${queryParams}`
    )
    return response.data as Order
  }

  async retrieveOrderInternal(
    orderId: string,
    type?: OrderRetrievalType
  ): Promise<Order> {
    const queryParams = new URLSearchParams()
    if (type) {
      queryParams.append("type", type)
    }
    const response = await this.client.get(
      `/order/v1/orders/${orderId}?${queryParams}`
    )
    return response.data as Order
  }

  async cancelOrder(
    clientId: string,
    orderId: string,
    cancelReason: string
  ): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/${orderId}/cancel`,
      { cancelReason }
    )
    return data as Order
  }

  async takeOutOrder(clientId: string, orderId: string): Promise<Order> {
    const { data } = await this.client.post(
      `/order/v1/clients/${clientId}/orders/${orderId}/take-out`
    )
    return data as Order
  }

  async patchOrder(
    clientId: string,
    invoiceNumber: string,
    payload: PatchOrderRequest
  ): Promise<Order> {
    const { data } = await this.client.patch(
      `/order/v1/clients/${clientId}/orders/${invoiceNumber}?type=invoice_number`,
      payload
    )
    return data as Order
  }

  async listRegions(
    queryParams: Record<string, string>
  ): Promise<ListRegionsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get<RawListRegionsResponse>(
      `/region/v1/regions?${paramsString}`
    )
    return {
      ...data,
      regions: data.regions.map((r) => {
        const pp = JSON.parse(
          r.polygon
            .split("POLYGON")
            .join("")
            .split("(")
            .join("[")
            .split(")")
            .join("]")
            .split(",[")
            .join(`,["`)
            .split("],")
            .join(`"],`)
            .split("[[")
            .join(`[["`)
            .split("]]")
            .join(`"]]`)
        ) as string[][]
        const p = pp
          .map((arr) =>
            arr.map(
              (s) =>
                JSON.parse(`["` + s.split(",").join(`","`) + `"]`) as string[]
            )
          )
          .map((arr) =>
            arr.map((ss) => ss.map((e) => e.split(" ").map((n) => +n)))
          )[0]
        return { ...r, polygon: p }
      }),
    }
  }

  async retrieveRegion(
    regionId: string,
    type: string = "id"
  ): Promise<RetrieveRegionResponse> {
    const { data } = await this.client.get<RetrieveRegionResponse>(
      `/region/v1/regions/${regionId}?type=${type}`
    )
    return data
  }

  async listClientRegions(
    clientId: string,
    queryParams: Record<string, string>
  ): Promise<ListRegionsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get<RawListRegionsResponse>(
      `/region/v1/clients/${clientId}/regions?${paramsString}`
    )
    return {
      ...data,
      regions: data.regions.map((r) => {
        return { ...r, polygon: null }
      }),
    }
  }

  async createBilling(req: CreateBillingRequest): Promise<Billing> {
    const { data } = await this.client.post(`/billing/v1/billings`, req)
    return data as Billing
  }

  async listBillings(
    queryParams: Record<string, string>
  ): Promise<ListBillingsResponse[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/billing/v1/billings?${paramsString}`
    )
    return data as ListBillingsResponse[]
  }

  async getBilling(billingId: string): Promise<Billing> {
    const { data } = await this.client.get(`/billing/v1/billings/${billingId}`)
    return data as Billing
  }

  async listClientBillings(
    clientId: string,
    queryParams: Record<string, string>
  ): Promise<ListBillingsResponse[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/billing/v1/clients/${clientId}/billings?${paramsString}`
    )
    return data as ListBillingsResponse[]
  }

  async getClientBilling(
    clientId: string,
    billingId: string
  ): Promise<Billing> {
    const { data } = await this.client.get(
      `/billing/v1/clients/${clientId}/billings/${billingId}`
    )
    return data as Billing
  }

  async deleteBilling(billingId: string): Promise<void> {
    await this.client.delete(`/billing/v1/billings/${billingId}`)
  }

  async createVirtualAccount(
    clientId: string,
    billingId: string
  ): Promise<Billing> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/billings/${billingId}/virtual-account`
    )
    return data as Billing
  }

  async renewVirtualAccount(
    clientId: string,
    billingId: string
  ): Promise<Billing> {
    const { data } = await this.client.post(
      `/billing/v1/clients/${clientId}/billings/${billingId}/virtual-account/renew`
    )
    return data as Billing
  }

  async createTaxInvoice(billingId: string): Promise<TaxInvoice> {
    const { data } = await this.client.post(
      `/billing/v1/billings/${billingId}/tax-invoice`
    )
    return data as TaxInvoice
  }

  async listBillingOrders(
    billingId: string,
    params: ListBillingOrdersParams
  ): Promise<ListOrdersResponse> {
    const paramsString = withPagination(params)
    const { data } = await this.client.get(
      `/billing/v1/billings/${billingId}/orders?${paramsString}`
    )
    return data as ListOrdersResponse
  }

  async listClientBillingOrders(
    clientId: string,
    billingId: string,
    params: ListClientBillingOrdersParams
  ): Promise<ListOrdersResponse> {
    const paramsString = withPagination(params)
    const { data } = await this.client.get(
      `/billing/v1/clients/${clientId}/billings/${billingId}/orders?${paramsString}`
    )
    return data as ListOrdersResponse
  }

  async listClientUnbilledOrders(
    clientId: string,
    params: ListClientUnbilledOrdersParams
  ): Promise<ListOrdersResponse> {
    const paramsString = withPagination(params)
    if (params.filter) {
      const terms: string[] = []
      const { deliverDateFrom, deliverDateTo, hasShippingFee } = params.filter
      if (deliverDateFrom) terms.push(`deliver_date_from=${deliverDateFrom}`)
      if (deliverDateTo) terms.push(`deliver_date_to=${deliverDateFrom}`)
      if (hasShippingFee !== undefined)
        terms.push(`has_shipping_fee=${hasShippingFee ? "true" : "false"}`)
      paramsString.set("filter", terms.join(";"))
    }
    const { data } = await this.client.get(
      `/billing/v1/clients/${clientId}/unbilled-orders?${paramsString}`
    )
    return data as ListOrdersResponse
  }

  async listUnbilledOrdersSummary(
    params: ListUnbilledOrdersSummaryParams
  ): Promise<ListUnbilledOrdersSummaryResponse> {
    const paramsString = withPagination(params)
    if (params.filter) {
      const terms: string[] = []
      const { deliverDateFrom, deliverDateTo } = params.filter
      if (deliverDateFrom) terms.push(`deliver_date_from=${deliverDateFrom}`)
      if (deliverDateTo) terms.push(`deliver_date_to=${deliverDateTo}`)
      paramsString.set("filter", terms.join(";"))
    }
    const { data } = await this.client.get(
      `/billing/v1/clients/unbilled-orders/summary?${paramsString}`
    )
    return data as ListUnbilledOrdersSummaryResponse
  }
}
