import React, { createContext, FC, useContext } from "react"
import { Invoice } from "../invoice"
import { Taker } from "../taker"
import { Tracer } from "../tracer"
import { Tracker } from "../tracker"
import { MQTTAuth } from "../mqtt"

export type APIConfig = {
  takerBaseUrl?: string
  trackerBaseUrl?: string
  tracerBaseUrl?: string
  invoiceBaseUrl?: string
}

const APIContext = createContext<APIConfig>({})

export const APIProvider: FC<APIConfig> = (props) => {
  return (
    <APIContext.Provider
      value={{
        takerBaseUrl: props.takerBaseUrl,
        trackerBaseUrl: props.trackerBaseUrl,
        tracerBaseUrl: props.tracerBaseUrl,
        invoiceBaseUrl: props.invoiceBaseUrl,
      }}
    >
      {props.children}
    </APIContext.Provider>
  )
}

export function useTaker(accessToken: string) {
  const { takerBaseUrl } = useContext(APIContext)
  return new Taker(takerBaseUrl ?? "", accessToken)
}

export function useTracker(accessToken: string) {
  const { trackerBaseUrl } = useContext(APIContext)
  return new Tracker(trackerBaseUrl ?? "", accessToken)
}

export function useTracer(accessToken: string) {
  const { tracerBaseUrl } = useContext(APIContext)
  return new Tracer(tracerBaseUrl ?? "", accessToken)
}

export function useInvoice(accessToken: string) {
  const { invoiceBaseUrl } = useContext(APIContext)
  return new Invoice(invoiceBaseUrl ?? "", accessToken)
}

export function useMQTT(accessToken: string) {
  const { trackerBaseUrl: mqttAuthBaseUrl } = useContext(APIContext)
  return new MQTTAuth(mqttAuthBaseUrl ?? "", accessToken)
}
