import { useInvoice, useTaker } from "@today/api"
import { useAuthentication } from "@today/auth"

export function useTakerAuth() {
  const { accessToken } = useAuthentication()
  return useTaker(accessToken)
}

export function useInvoiceAuth() {
  const { accessToken } = useAuthentication()
  return useInvoice(accessToken)
}
