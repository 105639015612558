import { HoldDeliveryType, HoldPickUpType } from "@today/api/tracker"

export function formatHoldDeliveryType(type: HoldDeliveryType) {
  switch (type) {
    case "REFUSED":
      return "수취거부 반송"
    case "DATE_CHANGED":
      return "배송일 변경"
    case "ADDRESS_CHANGED":
      return "배송지 변경"
    case "WRONG_ADDRESS":
      return "고객 주소 오류"
    case "DAMAGED":
      return "물품 파손"
    case "MISCLASSIFIED":
      return "오분류"
    case "DELAYED":
      return "배송 지연"
    case "WRONG_PASSWORD":
      return "공동현관 비밀번호 부재"
    case "CANNOT_FIND_ENTRANCE":
      return "출입구 확인 불가"
    case "ACCESS_DENIED":
      return "출입 불가"
    case "LOSS_CONCERNED":
      return "분실 우려"
    case "BAD_ENVIRONMENT":
      return "기상악화"
    case "CANNOT_DO_REQUEST":
      return "요청이행 불가"
  }
}

export function formatHoldPickUpType(type: HoldPickUpType) {
  switch (type) {
    case "CANCELED":
      return "고객 의사 변경"
    case "DATE_CHANGED":
      return "회수일 변경"
    case "ADDRESS_CHANGED":
      return "회수지 변경"
    case "NO_LOAD":
      return "물품 부재"
    case "OTHER_COMPANY_LOAD":
      return "타 택배사 회수"
    case "BAD_PACKING":
      return "포장 불량"
    case "CANNOT_DISTINGUISH_LOAD":
      return "물품 구분 불가"
    case "WRONG_PASSWORD":
      return "공동현관 비밀번호 부재"
    case "ACCESS_DENIED":
      return "출입 불가"
    case "BAD_ENVIRONMENT":
      return "기상악화"
    case "CANNOT_DO_REQUEST":
      return "요청이행 불가"
  }
}
