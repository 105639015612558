import { Invoice } from "@today/api/invoice"
import saveAs from "file-saver"
import _ from "lodash"
import { sleepAsync } from "@today/lib"
import dayjs from "dayjs"

export const CHUNK_SIZE = 100

export async function createInvoicePdf(
  invoice: Invoice,
  clientId: string,
  orderIds: string[],
  onCreatedInvoices: (createdOrderIds: string[]) => void,
  fileName: string = "invoices",
  print?: boolean
): Promise<void> {
  if (print && orderIds.length > CHUNK_SIZE)
    throw Error(
      `바로 인쇄 기능은 ${CHUNK_SIZE}개 이하의 주문을 선택 했을 때만 가능합니다.`
    )

  const printDate = dayjs().format("YYYY-MM-DDTHH:mm")

  const totalPageCount = orderIds.length
  const chunks = _.chunk(orderIds, CHUNK_SIZE)
  let index = 0
  for (const chunk of chunks) {
    let retry = 0
    const MAX_RETRY = print ? 1 : 3
    while (true) {
      try {
        const pdf = await invoice.retrieveInvoicePdfInternal(
          chunk,
          totalPageCount,
          index + 1
        )
        const blob = await fetch(pdf).then((res) => res.blob())
        if (print) {
          const fileURL = window.URL.createObjectURL(blob)
          const invoiceWindow = await window.open(
            fileURL,
            fileURL.toString(),
            "height=600width=400"
          )
          invoiceWindow!.focus()
          invoiceWindow!.print()
        } else {
          saveAs(
            blob,
            `${fileName}_${printDate}_[(${index + 1}-${
              index + chunk.length
            }/${totalPageCount}]).pdf`
          )
        }
        index += chunk.length
        break
      } catch (e) {
        if (++retry >= MAX_RETRY) {
          throw e
        }
      }
    }
    onCreatedInvoices(chunk)
    await sleepAsync(2000)
  }
}
