import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"

export class MQTTAuth {
  client: AxiosInstance

  constructor(baseUrl: string, token?: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseUrl,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      })
    )
  }

  async getPreSignedURL(): Promise<string> {
    const { data } = await this.client.get(`/mqtt/v1/auth/signed-wss-url`)
    return data.url
  }
}
