import {
  SSRCookies,
  SSRKeycloakProvider,
  useKeycloak,
} from "@react-keycloak/ssr"
import { useForceUpdate, useInterval } from "@today/lib"
import cookie from "cookie"
import { IncomingMessage } from "http"
import { KeycloakInstance } from "keycloak-js"
import { AppContext } from "next/app"
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { AuthInfo, KeycloakOption, UserInfo } from "./types"

const defaultUserInfo = () => ({
  isAuthenticated: false,
  name: "",
  email: "",
  realmRoles: [],
  clientRoles: [],
  outsourcingOrganizationId: undefined,
})

const UserInfoContext = createContext<UserInfo>(defaultUserInfo())

const defaultAuthInfo = () => ({
  isAuthenticated: false,
  accessToken: "",
  refreshToken: "",
  idToken: "",
  identity: {},
  realmRoles: [],
  clientRoles: [],
  async logout() {},
  outsourcingOrganizationId: undefined,
})

const AuthContext = createContext<AuthInfo>(defaultAuthInfo())

type Props = KeycloakOption &
  AuthInitialProps & {
    children?: React.ReactNode
  }

/**
 * 인증 정보를 제공하는 프로바이더.
 *
 * @see useAuthentication()
 * @see useUserInfo()
 */
export function AuthenticationProvider({
  url,
  realm,
  clientId,
  authInitialProp,
  children,
}: Props) {
  return (
    <SSRKeycloakProvider
      persistor={SSRCookies(authInitialProp.cookies)}
      keycloakConfig={{ url, realm, clientId }}
      autoRefreshToken
      initOptions={{
        onLoad: "login-required",
      }}
    >
      <KeycloakUserInfo>{children}</KeycloakUserInfo>
    </SSRKeycloakProvider>
  )
}

export interface AuthInitialProps {
  authInitialProp: {
    cookies: unknown
  }
}

function parseCookies(req: IncomingMessage) {
  return cookie.parse(req.headers.cookie || "")
}

export function withAuth(
  getInitialProps: (context: AppContext) => Promise<any>
): (context: AppContext) => Promise<any> {
  return async (context: AppContext) => {
    return {
      ...(await getInitialProps(context)),
      authInitialProp: {
        cookies: context.ctx.req ? parseCookies(context.ctx.req) : {},
      },
    }
  }
}

const KeycloakUserInfo: FC = ({ children }) => {
  const { initialized, keycloak } = useKeycloak<KeycloakInstance>()
  const [authInfo, setAuthInfo] = useState<AuthInfo>(defaultAuthInfo())
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo())
  const forceUpdate = useForceUpdate()
  // Force refresh (https://github.com/react-keycloak/react-keycloak/issues/147)
  useInterval(() => {
    keycloak?.updateToken(60)
    forceUpdate()
  }, 30 * 1000) // every 30s
  useEffect(() => {
    if (!initialized || !keycloak) {
      setAuthInfo(defaultAuthInfo())
      setUserInfo(defaultUserInfo())
      return
    }
    const auth = convertToAuthInfo(keycloak)
    const user = {
      isAuthenticated: auth.isAuthenticated,
      name: auth.identity.name,
      email: auth.identity.email,
      phoneNumber: auth.identity.phoneNumber,
      realmRoles: auth.realmRoles,
      clientRoles: auth.clientRoles,
      clientId: auth.identity.client_id,
      sellerName: auth.identity.seller_name,
      outsourcingOrganizationId: auth.outsourcingOrganizationId,
    }
    setAuthInfo(auth)
    setUserInfo(user)
  }, [initialized, keycloak, keycloak?.token])
  return (
    <AuthContext.Provider value={authInfo}>
      <UserInfoContext.Provider value={userInfo}>
        {children}
      </UserInfoContext.Provider>
    </AuthContext.Provider>
  )
}

function convertToAuthInfo(keycloak: KeycloakInstance): AuthInfo {
  return {
    isAuthenticated: keycloak.authenticated || false,
    accessToken: keycloak.token || "",
    refreshToken: keycloak.refreshToken || "",
    idToken: keycloak.idToken || "",
    identity: keycloak.idTokenParsed || {},
    realmRoles: keycloak.tokenParsed?.realm_access?.roles || [],
    clientRoles:
      keycloak.tokenParsed?.resource_access?.[keycloak.clientId!]?.roles || [],
    clientId: keycloak.tokenParsed?.clientId,
    logout: keycloak.logout,
    outsourcingOrganizationId:
      keycloak.tokenParsed?.outsourcing_organization_id || undefined,
  }
}

/**
 * 현재 인증 정보를 반환한다.
 *
 * @requires AuthenticationProvider
 */
export function useAuthentication() {
  return useContext(AuthContext)
}

/**
 * 현재 유저 정보를 반환한다.
 *
 * @requires AuthenticationProvider
 */
export function useUserInfo() {
  return useContext(UserInfoContext)
}
