export const PolicyTypes = [
  "TODAY_EVENING",
  "TODAY_OVERNIGHT",
  "GOODS_TO_LUCK",
  "HANJIN",
  "MNP",
  "YLP",
  "CJ",
  "SME",
] as const

export type PolicyType = (typeof PolicyTypes)[number]
