import { DeliveryClass } from "@today/api/common"

export function getDeliveryClassName(deliveryClass: DeliveryClass) {
  switch (deliveryClass) {
    case "TO_19":
      return "저녁도착"
    case "TO_24":
      return "오늘도착"
    case "TO_48":
      return "내일도착"
    case "FROM_72":
      return "빠른회수"
    case "INCHEON_24":
      return "SME당일"
    case "INCHEON_48":
      return "SME일반"
    case "FORWARDING_24":
      return "택배위탁"
    case "RETURNING":
      return "빠른반품"
    default:
      return deliveryClass
  }
}
