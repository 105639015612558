import { APIProvider } from "@today/api"
import { AuthenticationProvider, AuthInitialProps, withAuth } from "@today/auth"
import axios from "axios"
import { BaseProvider, LightTheme, LocaleProvider } from "baseui"
import { ToasterContainer } from "baseui/toast"
import { AppContext, AppProps } from "next/app"
import Head from "next/head"
import { RecoilRoot } from "recoil"
import { Provider as StyletronProvider } from "styletron-react"
import { SWRConfig } from "swr"
import { NavBar } from "../components"
import { RequiredNoticeModal } from "../components/modal/RequiredNoticeModal"
import { authConfig, INVOICE_BASE_URL, TAKER_BASE_URL } from "../config"
import locale from "../locale"
import "../styles/globals.css"
import { styletron } from "../styletron"

function App({
  Component,
  pageProps,
  authInitialProp,
}: AppProps & AuthInitialProps) {
  return (
    <>
      <Head>
        <title>Today 파트너</title>
        <meta name="google" content="notranslate" />
      </Head>
      <RecoilRoot>
        <AuthenticationProvider
          {...authConfig}
          authInitialProp={authInitialProp}
        >
          <APIProvider
            takerBaseUrl={TAKER_BASE_URL}
            invoiceBaseUrl={INVOICE_BASE_URL}
          >
            <SWRConfig
              value={{
                fetcher: (url) => axios.get(url).then((res) => res.data),
              }}
            >
              <StyletronProvider value={styletron}>
                <BaseProvider theme={LightTheme}>
                  <LocaleProvider locale={locale}>
                    <ToasterContainer autoHideDuration={3000}>
                      <div className="flex h-screen flex-col">
                        <NavBar />
                        <div
                          className="flex-1 overflow-hidden"
                          style={{
                            wordBreak: "keep-all",
                          }}
                        >
                          <Component {...pageProps} />
                        </div>
                      </div>
                      <RequiredNoticeModal />
                    </ToasterContainer>
                  </LocaleProvider>
                </BaseProvider>
              </StyletronProvider>
            </SWRConfig>
          </APIProvider>
        </AuthenticationProvider>
      </RecoilRoot>
    </>
  )
}

App.getInitialProps = withAuth(async (context: AppContext) => ({}))

export default App
