export const MY_BASE_URL = process.env.NEXT_PUBLIC_MY_BASE_URL as string
export const TAKER_BASE_URL = process.env.NEXT_PUBLIC_TAKER_BASE_URL as string
export const TRACKER_BASE_URL = process.env
  .NEXT_PUBLIC_TRACKER_BASE_URL as string
export const INVOICE_BASE_URL = process.env
  .NEXT_PUBLIC_INVOICE_BASE_URL as string
export const KEYCLOAK_JWT = process.env.KEYCLOAK_JWT as string
export const authConfig = {
  url: process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL as string,
  realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string,
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
}
export const ENV = process.env.NEXT_PUBLIC_ENV as string
