import { Client } from "@today/api/taker"
import { useUserInfo } from "@today/auth"
import { Button } from "baseui/button"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import dayjs from "dayjs"
import { useRecoilState } from "recoil"
import useSWR from "swr"
import { delayNoticeConfirmTimeState } from "../../state"
import { useSWRWithAuth } from "../../utils"

type RequiredNoticeModalProps = {
  onFinished?(): void
}

export function RequiredNoticeModal({
  onFinished,
  ...props
}: RequiredNoticeModalProps) {
  const { clientId } = useUserInfo()
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { data: notice } = useSWR<{
    title: string
    content: string
    image: string
    button: string
  }>(`/api/sme-notice`)
  const [confirmTime, setConfirmTime] = useRecoilState(
    delayNoticeConfirmTimeState
  )
  const showsModal =
    client?.role === "SME" &&
    notice &&
    !!(notice.title || notice.content || notice.image) &&
    (!confirmTime ||
      dayjs().startOf("day").diff(dayjs(confirmTime).endOf("day")) > 0)
  return (
    <Modal
      isOpen={showsModal}
      closeable={false}
      overrides={{
        Dialog: {
          style: {
            overflow: "hidden",
          },
        },
      }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {notice?.image && <img src={notice.image} alt="" />}
      {notice?.title && <ModalHeader>{notice?.title}</ModalHeader>}
      <ModalBody className="flex flex-col gap-y-4 text-base">
        {notice?.content &&
          notice.content
            .split("\n\n")
            .map((paragraph) => (
              <div className="whitespace-pre-line">{paragraph}</div>
            ))}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            setConfirmTime(new Date())
          }}
        >
          {notice?.button}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
