type ClientConfig = {
  hideProductCount?: boolean
  useClientShippingId?: boolean
  showReturningProducts?: boolean
}

const FMJ: ClientConfig = {
  hideProductCount: true,
}

const HANJIN: ClientConfig = {
  useClientShippingId: true,
}

const W_SHOPPING: ClientConfig = {
  showReturningProducts: true,
}

export const CLIENT_CONFIGS: { [k: string]: ClientConfig } = {
  "120670196598": FMJ,
  "560868260125": FMJ,
  "100928880033": FMJ,
  "510777721850": FMJ,
  "164880727013": HANJIN,
  "558619842636": HANJIN,
  "195720472966": W_SHOPPING,
  "549217590275": W_SHOPPING,
}
