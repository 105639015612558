const DEFAULT_PAGE_SIZE = 100

export type PaginationRequest<T = {}> = T & {
  pageToken?: string
  pageSize?: number
}

export type PaginationResponse<ResKey extends string, Res> = {
  [k in ResKey]: Res[]
} & { nextPageToken: string; totalCount: number }

export async function fetchAllPages<ResKey extends string, Res>(
  k: ResKey,
  q: { [key: string]: string },
  fetch: (q: {
    [key: string]: string
  }) => Promise<PaginationResponse<ResKey, Res>>,
  useCamel?: boolean
): Promise<Res[]> {
  let res: Res[] = []
  let firstFetch = true
  let nextPageToken = ""
  while (firstFetch || nextPageToken) {
    const data = await fetch({
      ...q,
      ...(useCamel
        ? {
            pageToken: nextPageToken,
            pageSize: q.page_size ?? DEFAULT_PAGE_SIZE,
          }
        : {
            page_token: nextPageToken,
            page_size: q.page_size ?? DEFAULT_PAGE_SIZE,
          }),
    })
    res = [...res, ...(data[k] ?? [])]
    firstFetch = false
    nextPageToken = data.nextPageToken
  }
  return res
}

export function withPagination(params?: PaginationRequest) {
  const p = new URLSearchParams()
  if (!params) return p
  if (params.pageSize) p.append("page_size", `${params.pageSize}`)
  if (params.pageToken) p.append("page_token", `${params.pageToken}`)
  return p
}
