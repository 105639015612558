import { ListOrdersResponse } from "@today/api/taker"
import { useCallback, useEffect } from "react"
import { useSWRInfiniteWithAuth } from "."

const PAGE_SIZE = 100

export function useOrders({
  clientId,
  fromDate,
  toDate,
}: {
  clientId?: string
  fromDate: string
  toDate: string
}) {
  const getSWRKey = useCallback(
    (pageIndex: number, previousPageData: ListOrdersResponse | null) => {
      if (!clientId) return null
      // Reached the end
      if (previousPageData && !previousPageData.nextPageToken) return null
      const token = previousPageData?.nextPageToken
      if (!fromDate && !toDate) {
        return null
      }
      const params = new URLSearchParams()
      if (fromDate) params.append("fromDate", fromDate)
      if (toDate) params.append("toDate", toDate)
      if (token) params.append("pageToken", token)
      return clientId && `/api/clients/${clientId}/orders?${params.toString()}`
    },
    [clientId, fromDate, toDate]
  )
  const {
    data: ordersResponses,
    mutate,
    size,
    setSize,
  } = useSWRInfiniteWithAuth<ListOrdersResponse>(getSWRKey)
  const isLoading = size > 0 && !ordersResponses?.[size - 1]
  const firstPage = ordersResponses?.[0]
  useEffect(() => {
    if (firstPage) {
      setSize(Math.ceil(firstPage.totalCount / PAGE_SIZE))
    }
  }, [firstPage, setSize])
  const orders = ordersResponses?.flatMap((resp) => resp.orders)
  return {
    orders,
    count: ordersResponses ? firstPage?.totalCount ?? 0 : undefined,
    mutate,
    isLoading,
  }
}
